import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getAccessToken } from "../../services/amplify";
import { Pagination } from "../../types/pagination";
import { Quote } from "../../types/quotes";
import { lossTypesCategoriesMap } from "../../utils/loss-types-categories-map";
import { ServerError } from "../../utils/server-error";

type QuotesProps = {
  resellerId?: string;
  defaultSelectedStatuses?: string[];
};

export const useQuotes = ({
  resellerId,
  defaultSelectedStatuses = [],
}: QuotesProps) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    defaultSelectedStatuses,
  );

  const statusQuery = selectedStatuses.length
    ? `&${selectedStatuses.map((status) => `statuses=${status}`).join("&")}`
    : "";

  const searchQuery = search ? `&search=${search}` : "";

  const resellerQuery = resellerId ? `&reseller_id=${resellerId}` : "";

  const hasFiltersAppliedQuotes = Boolean(
    search || selectedStatuses.length > 0,
  );

  const query = useQuery<Pagination<Quote[]>>({
    queryKey: ["quotes", searchQuery, statusQuery, resellerQuery, page],
    retry: false,
    queryFn: async () => {
      const accessToken = await getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_API_PRODUCTS}/internal/v3/quotes/?page=${page}&page_size=25${resellerQuery}${searchQuery}${statusQuery}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.json();

      if (!response?.ok) {
        throw new ServerError(data.errors);
      }

      return {
        metadata: data.metadata,
        links: data.links,
        data: formatQuotes(data.data),
      };
    },
  });

  return {
    ...query,
    page,
    setPage,
    hasFiltersAppliedQuotes,
    search,
    setSearch,
    selectedStatuses,
    setSelectedStatuses,
  };
};

export const formatQuotes = (quotes: any[]) =>
  quotes.map((rawQuote: any) => ({
    id: rawQuote.id,
    name: rawQuote.name,
    tags: [],
    status: rawQuote.status,
    dateCreated: rawQuote.date_created,
    products: rawQuote.quote_details.map((rawProduct: any) => ({
      quoteDetailId: rawProduct.id,
      productPriceId: rawProduct.product_price_id,
      seats: rawProduct.quantity,
      cost: rawProduct.cost,
      msrp: rawProduct.msrp,
      interval: rawProduct.interval,
      productName: rawProduct.product.friendly_name,
      clientPrice: rawProduct.client_price,
      itemRetailValue: rawProduct.product.max_device_value_usd || 0,
      serviceFee: rawProduct.product.deductible_schedules?.[0]?.deductible || 0,
      deviceType: {
        key: rawProduct.product.covered_item_specifications?.[0]?.item_type.id,
        label:
          rawProduct.product.covered_item_specifications?.[0]?.item_type.name,
      },
      subDeviceType: rawProduct.product.covered_item_specifications?.[0]
        ?.item_group?.id
        ? {
            key: rawProduct.product.covered_item_specifications[0].item_group
              .id,
            label:
              rawProduct.product.covered_item_specifications[0].item_group.name,
          }
        : null,
      coverageTypes: rawProduct.product.covered_loss_type_categories.map(
        (item: string) => lossTypesCategoriesMap[item],
      ),
      displayOrder: rawProduct.display_order || 0,
    })),
    client: rawQuote.client_information
      ? {
          id: rawQuote.client_information.id,
          contracts: 0,
          companyName: rawQuote.client_information.company_name,
          corporateName: rawQuote.client_information.corporate_name,
          domainNameUrl: rawQuote.client_information.domain_name_url,
          firstName: rawQuote.client_information.first_name,
          lastName: rawQuote.client_information.last_name,
          primaryContactEmail:
            rawQuote.client_information.primary_contact_email,
          resellerId: rawQuote.client_information.reseller_id,
        }
      : null,
    author: {
      id: rawQuote.author.id,
      email: rawQuote.author.email,
      first_name: rawQuote.author.first_name,
      last_name: rawQuote.author.last_name,
    },
    prices: rawQuote?.quote_details?.reduce(
      (
        accumulator: {
          cost: number;
          msrp: number;
          clientPrice: number;
        },
        item: any,
      ) => ({
        cost: accumulator.cost + (item.cost * item.quantity || 0),
        msrp: accumulator.msrp + (item.msrp * item.quantity || 0),
        clientPrice:
          accumulator.clientPrice + (item.client_price * item.quantity || 0),
      }),
      {
        msrp: 0,
        cost: 0,
        clientPrice: 0,
      },
    ),
    catalog: null,
  }));
