export enum PostHogEvent {
  QUOTE_START = "quote_start",
  QUOTE_COMPLETE = "quote_complete",
  QUOTE_REQUEST_CONVERT = "quote_request_convert",
  QUOTE_CONVERTED = "quote_converted",
  QUOTE_UPDATED = "quote_updated",
}

export enum OpsFlags {
  RESELLERS_MANAGING_CLIENTS = "resellers-managing-clients",
  RESELLERS_MASTER_AGENTS = "resellers-master-agents",
  PROSPECTS = "ops_prospects",
}

export enum ReleaseFlags {
  SHOW_CLAIMS_AT_RESELLER = "show-claims-at-reseller",
  // Release Date: 10-29-2024
  // Scheduled Removal: 11-29-2024
  URL_INPUT_ON_PROSPECT_CREATION = "release_url-input-on-prospect-creation",
  // Release Date: 10-29-2024
  // Scheduled Removal: 11-29-2024
  CLAIMS_FNOL = "release_claims-fnol",
  // Release Date: 10-29-2024
  // Scheduled Removal: 11-29-2024
  EMPTY_TABLE = "release_empty-table",
  // Release Date: 10-29-2024
  // Scheduled Removal: 11-29-2024
  MOVE_POLICIES_TO_CLIENTS = "release_move-policies-to-clients",
  // Release Date: 10-29-2024
  // Scheduled Removal: 11-29-2024
  RESELLER_TUTORIALS = "release_reseller-tutorial",
  // Release Date: 11-08-2024
  // Scheduled Removal: 12-08-2024
  CLAIMS_SAME_UNIT_REPAIR = "release_claims-same-unit-repair",
  // Release Date: TBD
  // Scheduled Removal: TBD
  QUOTE_MODAL_CATALOG_SELECTION = "release_quote-modal-catalog-selection",
  // Release Date: 01-24-2025
  // Scheduled Removal: 02-24-2025
}
